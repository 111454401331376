var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-wrapper auth-v2" },
    [
      _c(
        "b-row",
        { staticClass: "auth-inner m-0" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-none d-lg-flex align-items-center p-5",
              attrs: { lg: "8" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-lg-flex align-items-center justify-content-center px-5"
                },
                [
                  _c("b-img", {
                    attrs: { src: _vm.imgUrl, fluid: "", alt: "Cancom" }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
              attrs: { lg: "4" }
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "px-xl-2 mx-auto",
                  attrs: { sm: "8", md: "6", lg: "12" }
                },
                [
                  _c(
                    "b-card-title",
                    {
                      staticClass: "font-weight-bold mb-1",
                      attrs: { "title-tag": "h2" }
                    },
                    [_vm._v(" New Password 🔒 ")]
                  ),
                  _c("b-card-text", { staticClass: "mb-2" }, [
                    _vm._v(" Please enter a new password."),
                    _c("br"),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Password requirements:"),
                      _c("br"),
                      _vm._v(
                        " - Your new password must be different from previously used passwords."
                      ),
                      _c("br"),
                      _vm._v(" - Contains at least 1 number"),
                      _c("br"),
                      _vm._v(" - Contains at least 1 uppercase letter"),
                      _c("br"),
                      _vm._v(" - Contains at least 1 lowercase letter"),
                      _c("br"),
                      _vm._v(" - Contains at least 1 special character:"),
                      _c("br"),
                      _vm._v(
                        ' (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , : ; | _ ~ ` + =) '
                      )
                    ])
                  ]),
                  _c(
                    "validation-observer",
                    { ref: "simpleRules" },
                    [
                      _c(
                        "b-form",
                        {
                          staticClass: "auth-reset-password-form mt-2",
                          attrs: { method: "POST" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.validationForm($event)
                            }
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "New Password",
                                "label-for": "reset-password-new"
                              }
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Password",
                                  vid: "Password",
                                  rules: "required|password"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "b-input-group",
                                          {
                                            staticClass: "input-group-merge",
                                            class:
                                              errors.length > 0
                                                ? "is-invalid"
                                                : null
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-merge",
                                              attrs: {
                                                id: "reset-password-new",
                                                type: _vm.password1FieldType,
                                                state:
                                                  errors.length > 0
                                                    ? false
                                                    : null,
                                                name: "reset-password-new",
                                                placeholder: "············"
                                              },
                                              model: {
                                                value: _vm.password,
                                                callback: function($$v) {
                                                  _vm.password = $$v
                                                },
                                                expression: "password"
                                              }
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              { attrs: { "is-text": "" } },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    icon:
                                                      _vm.password1ToggleIcon
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.togglePassword1Visibility
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-for": "reset-password-confirm",
                                label: "Confirm Password"
                              }
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Confirm Password",
                                  rules: "required|confirmed:Password"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "b-input-group",
                                          {
                                            staticClass: "input-group-merge",
                                            class:
                                              errors.length > 0
                                                ? "is-invalid"
                                                : null
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-merge",
                                              attrs: {
                                                id: "reset-password-confirm",
                                                type: _vm.password2FieldType,
                                                state:
                                                  errors.length > 0
                                                    ? false
                                                    : null,
                                                name: "reset-password-confirm",
                                                placeholder: "············"
                                              },
                                              model: {
                                                value: _vm.cPassword,
                                                callback: function($$v) {
                                                  _vm.cPassword = $$v
                                                },
                                                expression: "cPassword"
                                              }
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              { attrs: { "is-text": "" } },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    icon:
                                                      _vm.password2ToggleIcon
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.togglePassword2Visibility
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                block: "",
                                type: "submit",
                                variant: "primary",
                                disabled: _vm.preventDoubleClick
                              }
                            },
                            [_vm._v(" Set New Password ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "text-center mt-2" },
                    [
                      _c(
                        "b-link",
                        { attrs: { to: { name: "auth-login" } } },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "ChevronLeftIcon" }
                          }),
                          _vm._v(" Back to login ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }